import {
  AddCircleOutlineIcon,
  Box,
  Button,
  CircularProgress,
  Pagination,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOffers } from "../../features/actions/offers/offers.actions";
import { StateStorage } from "../../models/OffersState";
import { AppDispatch } from "../../store/store";
import { TableOffers } from "./components/TableOffers";

export const OfferHistory = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { isLoadingOffers, offersPagination } = useSelector(
    (state: StateStorage) => state.offersState
  );

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getOffers({
        page: 0,
        size: 15,
      })
    );
  }, []);
  return (
    <Box className="historic-page-container">
      <Box className="historic-page-header">
        <Box className="text-container">
          <Typography
            variant="h4"
            fontWeight="bold"
            color={enerbitColors.primary[600]}
          >
            Ofertador
          </Typography>
          <Typography
            variant="h6"
            fontWeight="normal"
            color={enerbitColors.neutral[700]}
          >
            Crea, administra y monitorea el rendimiento de las ofertas en la
            aplicación.
          </Typography>
        </Box>
        <Button
          className="historic-page-button-create-offer"
          endIcon={<AddCircleOutlineIcon />}
          color="primary"
          variant="contained"
          onClick={() => {
            navigate(`/offers/new`);
          }}
        >
          Crear oferta
        </Button>
      </Box>
      <Typography
        variant="h4"
        fontWeight="bold"
        color={enerbitColors.primary[600]}
        fontSize="22px"
        sx={{
          marginTop: "54px",
        }}
      >
        Histórico de ofertas
      </Typography>
      {isLoadingOffers ? (
        <Box className="loading">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {offersPagination?.items.length === 0 ? (
            <></>
          ) : (
            <>
              <TableOffers />
              <Box className="pagination-container">
                <Pagination
                  shape="rounded"
                  count={offersPagination?.pages}
                  page={(offersPagination?.page ?? 0) + 1}
                  onChange={(event: ChangeEvent<unknown>, value: number) => {
                    console.log(value);
                    dispatch(
                      getOffers({
                        page: value - 1,
                        size: 15,
                      })
                    );
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
