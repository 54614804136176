import { AdapterMoment, Box, Typography, enerbitColors } from "@enerbit/base";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  onEndedAtChanged,
  onStartedAtChanged,
} from "../../../features/offers/offers";
import { AppDispatch } from "../../../store/store";

const now = moment();
const currentDateFormatted = moment().format("YYYY-MM-DDTHH:mm");
export const Calendar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment(currentDateFormatted)
  );
  const [endDate, setEndDate] = useState<moment.Moment>(
    moment(currentDateFormatted)
  );

  useEffect(() => {
    dispatch(onStartedAtChanged(startDate.toISOString()));
    dispatch(onEndedAtChanged(endDate.toISOString()));
  }, []);

  const minDate = now;

  const handleStartDateChange = (date: moment.Moment | null) => {
    if(date){
      setStartDate(date);
      dispatch(onStartedAtChanged(date.toISOString()));
    }
  };

  const handleEndDateChange = (date: moment.Moment | null) => {
    if(date){
      setEndDate(date);
      dispatch(onEndedAtChanged(date.toISOString()));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box className="calendar-container">
        <Typography
          fontSize="22px"
          fontWeight="bold"
          color={enerbitColors.primary.main}
        >
          Opciones de programación
        </Typography>
        <Typography
          fontSize="16px"
          color={enerbitColors.primary[700]}
          style={{ marginBottom: "35px" }}
        >
          Programa tu oferta en los momentos en los que tu audiencia es más
          activa.
        </Typography>

        <Typography fontSize="16px" color={enerbitColors.primary[700]}>
          Fecha de inicio
        </Typography>

        <DateTimePicker
          value={startDate}
          onChange={handleStartDateChange}
          minDate={minDate}
        />

        <Typography marginTop="12px" fontSize="16px" color={enerbitColors.primary[700]}>
          Fecha de finalización
        </Typography>

        <DateTimePicker
          
          value={endDate}
          onChange={handleEndDateChange}
          minDate={(startDate || minDate).add(1, "hour")}
        />
      </Box>
    </LocalizationProvider>
  );
};
