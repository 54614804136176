import { Box, Typography, enerbitColors } from "@enerbit/base";

export type OfferStatusProps = {
  isActive: boolean;
};

export const OfferStatus = ({ isActive }: OfferStatusProps) => {
  const getBackgroundColor = () => {
    return isActive ? enerbitColors.success[100] : enerbitColors.error[100];
  };

  const getTextColor = () => {
    return isActive ? enerbitColors.success[700] : enerbitColors.error[700];
  };

  const getText = () => {
    return isActive ? "Activa" : "Inactiva";
  };

  return (
    <Box
      className="card-offer-status"
      sx={{
        backgroundColor: getBackgroundColor(),
      }}
    >
      <Typography variant="body1" color={getTextColor()}>
        {getText()}
      </Typography>
    </Box>
  );
};
