import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { StateStorage } from "../../../models/OffersState";
import { OfferStatus } from "./OfferStatus";

export const TableOffers = () => {
  const { offersPagination } = useSelector(
    (state: StateStorage) => state.offersState
  );
  const [now] = useState(moment());

  return (
    <TableContainer sx={{ marginTop: "40px" }}>
      <Table
        className="Table-general"
        sx={{ minWidth: 650 }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">Nombre</TableCell>
            <TableCell align="left">Fecha de inicio</TableCell>
            <TableCell align="left">Fecha de fin</TableCell>
            <TableCell align="left">Estado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offersPagination?.items.map((offer) => {
            const ended_at = moment(offer.ended_at);
            return (
              <TableRow
                key={offer.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0, backgroundColor: '#F7F9FC' } }}
              >
                <TableCell component="th" scope="row">
                  {offer.name}
                </TableCell>
                <TableCell align="left">
                  {moment(offer.started_at).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell align="left">
                  {!offer.ended_at ? '' :  moment(offer.ended_at).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell align="left">
                  {<OfferStatus isActive={now.isBefore(ended_at)} />}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
