import { PrivateRoute } from "@enerbit/base";
import { HashRouter, Route, Routes } from "react-router-dom";
import { NewOffer } from "../pages/NewOffer/NewOffer";
import { OfferHistory } from "../pages/OffersHistory/OfferHistory";

export const Routers = () => {
  return (
    <PrivateRoute>
      <HashRouter>
        <Routes>
          <Route path="*" element={<OfferHistory />} />
          <Route path="/offers/new" element={<NewOffer />} />
        </Routes>
      </HashRouter>
    </PrivateRoute>
  );
};
