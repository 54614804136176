import { EnerbitPagination } from "@enerbit/base";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Offer } from "../../models/Offer";
import { OfferByUser } from "../../models/OfferByUser";
import { OfferType } from "../../models/OfferType";
import { OfferState } from "../../models/OffersState";
import {
  createCampaigns,
  createOffer,
  getOffers,
} from "../actions/offers/offers.actions";

const initialState: OfferState = {
  isLoadingOffers: false,
  isCreatingOffer: false,
  offersPagination: null,
  offersByUserCreated: null,
  offerCreated: null,
  createOfferForm: {
    campaingImage: undefined,
    description: null,
    name: null,
    offerType: OfferType.offerTypeUnspecified,
    principalButtonText: null,
    secondaryButtonText: null,
    endedAt: null,
    startedAt: null,
    usersFile: undefined,
    notificationTitle: "",
    notificationText: "",
  },
};

export const offersSlice = createSlice({
  name: "offersState",
  initialState: initialState,
  reducers: {
    onNameChanged: (state, action: PayloadAction<string>) => {
      state.createOfferForm.name = action.payload;
    },
    onDescriptionChanged: (state, action: PayloadAction<string>) => {
      state.createOfferForm.description = action.payload;
    },
    onPrincipalButtonChanged: (state, action: PayloadAction<string>) => {
      state.createOfferForm.principalButtonText = action.payload;
    },
    onSecondaryButtonChanged: (state, action: PayloadAction<string>) => {
      state.createOfferForm.secondaryButtonText = action.payload;
    },
    onOfferTypeChanged: (state, action: PayloadAction<string>) => {
      state.createOfferForm.offerType = action.payload;
    },
    onCampaingImageChanged: (state, action: PayloadAction<File | null>) => {
      state.createOfferForm.campaingImage = action.payload;
    },
    onUsersFileChanged: (state, action: PayloadAction<File | null>) => {
      state.createOfferForm.usersFile = action.payload;
    },
    onStartedAtChanged: (state, action: PayloadAction<string | null>) => {
      state.createOfferForm.startedAt = action.payload;
    },
    onEndedAtChanged: (state, action: PayloadAction<string | null>) => {
      state.createOfferForm.endedAt = action.payload;
    },
    resetForm: (state) => {
      state.createOfferForm = initialState.createOfferForm;
    },
    onNotificationTitleChanged: (state, action: PayloadAction<string>) => {
      state.createOfferForm.notificationTitle = action.payload;
    },
    onNotificationTextChanged: (state, action: PayloadAction<string>) => {
      state.createOfferForm.notificationText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOffers.pending.type, (state) => {
        state.isLoadingOffers = true;
        state.offersPagination = null;
      })
      .addCase(getOffers.rejected.type, (state) => {
        state.isLoadingOffers = false;
      })
      .addCase(
        getOffers.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<Offer>>) => {
          state.isLoadingOffers = false;
          state.offersPagination = action.payload;
        }
      )
      .addCase(createOffer.pending.type, (state) => {
        state.isCreatingOffer = true;
        state.offerCreated = null;
      })
      .addCase(createOffer.rejected.type, (state) => {
        state.isCreatingOffer = false;
        state.offerCreated = null;
      })
      .addCase(
        createOffer.fulfilled.type,
        (state, action: PayloadAction<Offer>) => {
          state.isCreatingOffer = false;
          state.offerCreated = action.payload;
        }
      )
      .addCase(createCampaigns.pending.type, (state) => {
        state.isCreatingOffer = true;
        state.offersByUserCreated = null;
      })
      .addCase(createCampaigns.rejected.type, (state) => {
        state.isCreatingOffer = false;
        state.offersByUserCreated = null;
      })
      .addCase(
        createCampaigns.fulfilled.type,
        (state, action: PayloadAction<OfferByUser[]>) => {
          state.isCreatingOffer = false;
          state.offerCreated = null;
          state.offersByUserCreated = action.payload;
        }
      );
  },
});

export const {
  onNameChanged,
  onOfferTypeChanged,
  onDescriptionChanged,
  onCampaingImageChanged,
  onUsersFileChanged,
  onPrincipalButtonChanged,
  onSecondaryButtonChanged,
  onStartedAtChanged,
  onEndedAtChanged,
  resetForm,
  onNotificationTitleChanged,
  onNotificationTextChanged,
} = offersSlice.actions;

export default offersSlice.reducer;
