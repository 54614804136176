import { ThemeConfig, initEnviroment } from "@enerbit/base";
import { Provider } from "react-redux";
import { Routers } from "./router/Routers";
import { store } from "./store/store";
import "./styles/style.scss";

// Inicializa el entorno
initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export const Root = () => {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <section className="Container-root">
          <Routers />
        </section>
      </ThemeConfig>
    </Provider>
  );
};
