import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Typography,
  enerbitColors,
} from "@enerbit/base";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
export const NewOfferHeader = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={(e) => {
        e.preventDefault();
        navigate("/offers");
      }}
    >
      <Typography
        className="custom-padding-top"
        fontSize="16px"
        color={enerbitColors.neutral.main}
        sx={{
          cursor: "pointer",
        }}
      >
        <span>Ofertador</span>
      </Typography>
    </Link>,

    <Typography
      className="custom-padding-top"
      fontSize="16px"
      key="2"
      color={enerbitColors.primary.main}
      fontWeight="bold"
    >
      <span>Crear oferta</span>
    </Typography>,
  ];

  return (
    <Box className="header-new-offer">
      <IconButton
        color="primary"
        onClick={() => {
          history.back();
        }}
      >
        <ArrowBackIosNewIcon fontSize="inherit" />
      </IconButton>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Box>
  );
};
