export enum OfferType {
  offerTypeUnspecified = "OFFER_TYPE_UNSPECIFIED",
  internal = "INTERNAL",
  external = "EXTERNAL",
}

export const offerTypeToUIMessage = (
  value: "offerTypeUnspecified" | "internal" | "external"
) => {
  switch (value) {
    case "offerTypeUnspecified":
      return "Sin especificar";
    case "internal":
      return "Interna";
    case "external":
      return "Externa";
  }
};
