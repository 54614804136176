import {
  Box,
  CardPdf,
  Checkbox,
  DragZone,
  enerbitColors,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@enerbit/base";
import { NotificationsNone } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onCampaingImageChanged,
  onDescriptionChanged,
  onNameChanged,
  onNotificationTextChanged,
  onNotificationTitleChanged,
  onOfferTypeChanged,
  onPrincipalButtonChanged,
  onSecondaryButtonChanged,
  onUsersFileChanged,
} from "../../../features/offers/offers";
import { CreateOfferForm } from "../../../models/CreateOfferForm";
import { OfferType, offerTypeToUIMessage } from "../../../models/OfferType";
import { StateStorage } from "../../../models/OffersState";
import { AppDispatch } from "../../../store/store";
import { enumKeys } from "../../../utils/utils";

export const FormInputs = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { createOfferForm, offerCreated } = useSelector(
    (state: StateStorage) => state.offersState
  );

  const [useTitleAsCampaignName, setUseTitleAsCampaignName] = useState(false);
  const [touched, setTouched] = useState<CreateOfferForm>(
    {} as CreateOfferForm
  );

  const handleBlur = (inputName: string) => {
    setTouched((prev) => {
      return { ...prev, [inputName]: true };
    });
  };

  const isAnEmptyValue = (value: string | null): boolean => {
    if (value == null) {
      return true;
    }
    return value.length === 0;
  };

  const getOfferTypeMenuItems = (): JSX.Element[] => {
    const menuItems: JSX.Element[] = [];
    for (const keyOfferType of enumKeys(OfferType)) {
      menuItems.push(
        <MenuItem key={keyOfferType} value={OfferType[keyOfferType]}>
          {offerTypeToUIMessage(keyOfferType)}
        </MenuItem>
      );
    }
    return menuItems;
  };

  useEffect(() => {
    if(offerCreated){
      setTouched({} as CreateOfferForm);
    }
  }, [offerCreated])

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormGroup>
            <InputLabel shrink className="label-input">
              Nombre
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="name"
              name="name"
              variant="outlined"
              placeholder="Ingresa nombre de la oferta"
              value={createOfferForm.name ?? ''}
              error={
                touched["name"] != null && isAnEmptyValue(createOfferForm.name)
              }
              helperText={
                touched["name"] != null && isAnEmptyValue(createOfferForm.name)
                  ? "Campo requerido"
                  : ""
              }
              onChange={(e) => {
                dispatch(onNameChanged(e.target.value));
              }}
              onBlur={(e) => handleBlur(e.target.name)}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <InputLabel shrink className="label-input">
              Tipo de oferta
            </InputLabel>
            <TextField
              fullWidth
              select
              className="TextField-without-border-radius"
              id="offerType"
              name="offerType"
              variant="outlined"
              placeholder="Seleccionar el tipo de oferta"
              value={createOfferForm.offerType ?? ''}
              onChange={(e) => {
                dispatch(onOfferTypeChanged(e.target.value));
              }}
            >
              {getOfferTypeMenuItems()}
            </TextField>
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel shrink className="label-input">
              Descripción
            </InputLabel>
            <TextField
              fullWidth
              multiline
              minRows={3}
              className="TextField-without-border-radius"
              id="description"
              name="description"
              variant="outlined"
              placeholder="Ingresa nombre de la oferta"
              value={createOfferForm.description ?? ''}
              error={
                touched["description"] != null &&
                isAnEmptyValue(createOfferForm.description)
              }
              helperText={
                touched["description"] != null &&
                isAnEmptyValue(createOfferForm.description)
                  ? "Campo requerido"
                  : ""
              }
              onChange={(e) => {
                dispatch(onDescriptionChanged(e.target.value));
              }}
              onBlur={(e) => handleBlur(e.target.name)}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel shrink className="label-input">
              Imagen campaña
            </InputLabel>
            {!createOfferForm.campaingImage ? (
              <>
                <DragZone
                  typeFile="img"
                  error={createOfferForm.campaingImage === null}
                  onDrop={(acceptedFiles, _, _event) => {
                    if (acceptedFiles.length > 0) {
                      dispatch(onCampaingImageChanged(acceptedFiles[0]));
                    }
                  }}
                />
                {createOfferForm.campaingImage === null && (
                  <Typography
                    fontSize="0.75rem"
                    color={enerbitColors.error.main}
                  >
                    Campo requerido
                  </Typography>
                )}
              </>
            ) : (
              <CardPdf
                typeFile={createOfferForm.campaingImage.name.split(".").pop()}
                isSuccess={true}
                bgColor="error"
                isClearFile={true}
                fileInfo={{
                  name: createOfferForm.campaingImage.name,
                  size: createOfferForm.campaingImage.size,
                }}
                progress={100}
                onClearFile={() => {
                  dispatch(onCampaingImageChanged(null));
                }}
              />
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel shrink className="label-input">
              Archivo usuarios
            </InputLabel>
            {!createOfferForm.usersFile ? (
              <>
                <DragZone
                  typeFile="csv"
                  error={createOfferForm.usersFile === null}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      dispatch(onUsersFileChanged(acceptedFiles[0]));
                    }
                  }}
                />
                {createOfferForm.usersFile === null && (
                  <Typography
                    fontSize="0.75rem"
                    color={enerbitColors.error.main}
                  >
                    Campo requerido
                  </Typography>
                )}
              </>
            ) : (
              <CardPdf
                typeFile={createOfferForm.usersFile.name.split(".").pop()}
                isSuccess={true}
                bgColor="error"
                isClearFile={true}
                fileInfo={{
                  name: createOfferForm.usersFile.name,
                  size: createOfferForm.usersFile.size,
                }}
                progress={100}
                onClearFile={() => {
                  dispatch(onUsersFileChanged(null));
                }}
              />
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <InputLabel shrink className="label-input">
              Texto para botón principal
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="principalButtonText"
              name="principalButtonText"
              variant="outlined"
              placeholder="ej: Aceptar"
              value={createOfferForm.principalButtonText ?? ''}
              error={
                touched["principalButtonText"] != null &&
                isAnEmptyValue(createOfferForm.principalButtonText)
              }
              helperText={
                touched["principalButtonText"] != null &&
                isAnEmptyValue(createOfferForm.principalButtonText)
                  ? "Campo requerido"
                  : ""
              }
              onChange={(e) => {
                dispatch(onPrincipalButtonChanged(e.target.value));
              }}
              onBlur={(e) => handleBlur(e.target.name)}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <InputLabel shrink className="label-input">
              Texto para botón secundario
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="secondaryButtonText"
              name="secondaryButtonText"
              variant="outlined"
              placeholder="ej: Rechazar"
              value={createOfferForm.secondaryButtonText ?? ''}
              error={
                touched["secondaryButtonText"] != null &&
                isAnEmptyValue(createOfferForm.secondaryButtonText)
              }
              helperText={
                touched["secondaryButtonText"] != null &&
                isAnEmptyValue(createOfferForm.secondaryButtonText)
                  ? "Campo requerido"
                  : ""
              }
              onChange={(e) => {
                dispatch(onSecondaryButtonChanged(e.target.value));
              }}
              onBlur={(e) => handleBlur(e.target.name)}
            />
          </FormGroup>
        </Grid>
        {/* Configuración de notificaciones */}
        <Grid item xs={12}>
          <Box className="notification-container">
            <Typography
              variant="h5"
              gutterBottom
              className="notification-title"
            >
              <NotificationsNone sx={{ marginRight: "8px" }} />
              Configuración de notificaciones
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormGroup>
                  <InputLabel shrink className="label-input">
                    Título de la notificación
                  </InputLabel>
                  <TextField
                    fullWidth
                    className="TextField-without-border-radius"
                    id="notificationTitle"
                    name="notificationTitle"
                    variant="outlined"
                    inputProps={{maxLength: 30}}
                    placeholder="Titulo de no mas de 30 caracteres."
                    value={createOfferForm.notificationTitle ?? ''}
                    error={
                      touched["notificationTitle"] != null &&
                      isAnEmptyValue(createOfferForm.notificationTitle)
                    }
                    helperText={
                      touched["notificationTitle"] != null &&
                      isAnEmptyValue(createOfferForm.notificationTitle)
                        ? "Campo requerido"
                        : ""
                    }
                    onChange={(e) => {
                      dispatch(onNotificationTitleChanged(e.target.value));
                    }}
                    onBlur={(e) => handleBlur(e.target.name)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <InputLabel shrink className="label-input">
                    Contenido de la notificación
                  </InputLabel>
                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    className="TextField-without-border-radius"
                    id="notificationText"
                    name="notificationText"
                    variant="outlined"
                    inputProps={{maxLength: 250}}
                    placeholder="Añade una pequeña descripción que acompañará a la notificación. No puede ser mayor a 250 caracteres."
                    value={createOfferForm.notificationText ?? ''}
                    error={
                      touched["notificationText"] != null &&
                      isAnEmptyValue(createOfferForm.notificationText)
                    }
                    helperText={
                      touched["notificationText"] != null &&
                      isAnEmptyValue(createOfferForm.notificationText)
                        ? "Campo requerido"
                        : ""
                    }
                    onChange={(e) => {
                      dispatch(onNotificationTextChanged(e.target.value));
                    }}
                    onBlur={(e) => handleBlur(e.target.name)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="useCampaignName"
                      checked={useTitleAsCampaignName}
                      onChange={(e) =>
                        setUseTitleAsCampaignName(e.target.checked)
                      }
                    />
                  }
                  label="Utilizar el nombre de la campaña"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
