import { Box, Button, Grid, Typography, enerbitColors } from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createCampaigns,
  createOffer,
} from "../../features/actions/offers/offers.actions";
import { StateStorage } from "../../models/OffersState";
import { AppDispatch } from "../../store/store";
import { Calendar } from "./components/Calendar";
import { FormInputs } from "./components/FormInputs";
import { NewOfferHeader } from "./components/NewOfferHeader";
import { resetForm } from "../../features/offers/offers";

export const NewOffer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { createOfferForm, offerCreated } = useSelector(
    (state: StateStorage) => state.offersState
  );

  useEffect(() => {
    const createNewCampaign = () => {
      if (offerCreated) {
        const offerForm = { ...createOfferForm };
        console.log(offerForm);
        dispatch(
          createCampaigns({
            file: offerForm.usersFile!,
            notificationText: offerForm.notificationText || "",
            notificationTitle: offerForm.notificationTitle || "",
            offerId: offerCreated.id,
          })
        );
        dispatch(resetForm());
      }
    };
    createNewCampaign();
  }, [offerCreated]);

  const isValidForm = (): boolean => {
    if (!createOfferForm.name || createOfferForm.name.length === 0) {
      return false;
    }
    if (
      !createOfferForm.description ||
      createOfferForm.description.length === 0
    ) {
      return false;
    }
    if (
      !createOfferForm.principalButtonText ||
      createOfferForm.principalButtonText.length === 0
    ) {
      return false;
    }
    if (
      !createOfferForm.secondaryButtonText ||
      createOfferForm.secondaryButtonText.length === 0
    ) {
      return false;
    }
    if (!createOfferForm.campaingImage) {
      return false;
    }
    if (!createOfferForm.usersFile) {
      return false;
    }
    if (
      !createOfferForm.notificationTitle ||
      createOfferForm.notificationTitle.length === 0 ||
      createOfferForm.notificationTitle.length > 30
    ) {
      return false;
    }
    if (
      !createOfferForm.notificationText ||
      createOfferForm.notificationText.length === 0 ||
      createOfferForm.notificationText.length > 250
    ) {
      return false;
    }
    return true;
  };

  const onCancel = () => {
    navigate(`/offers/offers`);
  };

  const onOfferCreated = () => {
    if (isValidForm()) {
      dispatch(createOffer(createOfferForm));
    }
  };

  return (
    <Box
      sx={{
        padding: "30px 48px",
      }}
    >
      <NewOfferHeader />
      <Typography
        marginTop="36px"
        variant="h4"
        fontWeight="bold"
        color={enerbitColors.primary[700]}
      >
        Crear oferta
      </Typography>
      <Box className="new-offer-form-container">
        <Box width="60%">
          <FormInputs />
        </Box>
        <Box className="calendar-form">
          <Box maxWidth="430px">
            <Calendar />
            <Grid container spacing={2} marginTop="20px">
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={onCancel}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={!isValidForm()}
                  onClick={onOfferCreated}
                >
                  Crear nueva oferta
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
