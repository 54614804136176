import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateOfferForm } from "../../../models/CreateOfferForm";

export type GetOffersProps = {
  size: number;
  page: number;
};

export const getOffers = createAsyncThunk(
  "offers/getOffers",
  async ({ page, size }: GetOffersProps) => {
    const response = await api.get("offers/offers", {
      params: {
        size: size,
        page: page,
      },
    });
    return response.data;
  }
);

export const createOffer = createAsyncThunk(
  "offers/createOffer",
  async (data: CreateOfferForm) => {
    const response = await api.post(
      "/offers/offers",
      {
        file: data.campaingImage,
        name: data.name,
        button_accept_text: data.principalButtonText,
        button_deny_text: data.secondaryButtonText,
        description: data.description,
        started_at: data.startedAt,
        ended_at: data.endedAt,
        offer_type: data.offerType,
        onNotificationTitleChanged: data.notificationTitle,
        onNotificationTextChanged: data.notificationText,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }
);

export type CreateCampaingProps = {
  offerId: string;
  notificationTitle: string;
  notificationText: string;
  file: File;
};
export const createCampaigns = createAsyncThunk(
  "/offers/createCampaigns",
  async (data: CreateCampaingProps) => {
    const response = await api.post(
      "/offers/campaigns/",
      {
        file: data.file,
        offer_id: data.offerId,
        notification_title: data.notificationTitle,
        notification_text: data.notificationText,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }
);
